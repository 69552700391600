body {
  background-color: rgb(255, 255, 255);
  font-family: Tahoma, sans-serif;
}
/* Header */
.title-brand{
  justify-content: space-between;
  margin-top: 17px;
  word-spacing: 12px;
  align-items:center;
  font-size:14px;
  font-weight: bold;
  color: rgb(5, 3, 63);
  white-space: nowrap;
  
}
.img-logo{
  margin-left: -60px;
}
.search-input{
  border-radius: 15px ;
  border: blue;
}
.appointment1{
  
  display: flex;
  justify-content:flex-end;
  margin: 10px 0px;
  display: flex;
  justify-content:center;
  align-items:center;
}
.appointment{
  background-color: #0c45d3;
  color: #ffffff;
  font-size: 22px;
  font-weight: bold;
  margin-top: 15px;
  margin-left: 75px;
  
}
.navbar-light{
  display: flex;
  justify-content:center;
  align-items: flex-end;
  background-color: rgba(255, 255, 255, 0.99);
  color: black;
  margin-left: -0px;
}
.navbar-light:hover{
  display: flex;
  justify-content:space-between;
  background-color:rgb(42, 60, 81,0.6);
}
.nav-link{
  font-size: 20px;
  display: flex;
  justify-content:space-between;
 
}
.nav-link:hover{
  max-width: 100px;
  background-color: white; 
 
}
/* Quote */
.get-quote{
  background-color: rgb(116, 180, 39);
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
}
.quot-form {
  margin-top: 15px;
}
.select-item{
  width:73%;
  height: 35px;
  /*position: absolute;*/
  overflow-x: hidden;
}
/*Map*/
.leaflet-container{
  height: 35vh;
  width: 98%;
  display: flex;
  justify-content: center;
}
.map-size{
  background-color: rgb(255, 255, 255);
  margin-top: 60px;
  margin-bottom: 40px;
 
}
/* Card from share*/
.card-title{
  margin-top: -40px;
  position:absolute;
  font-size: 30px;
  color:rgb(116, 180, 39);
}
.card-text{
  font-size: 20px;
}
/* Contact*/
.card-container{
  margin: 20px 0px;
  overflow: hidden;
  transition:0.1s;
  animation:ease-in;
  background-color: rgb(246, 246, 246);
}
.card-container:hover{
  transform:scale(1.015) ;
  box-shadow: 0px 0px 10px 0px;
}
.card-content{
  margin: 1rem;
  margin-top:1.5rem ;
}
.card-title1{
  text-align: center;
  margin: 0;
  color: rgb(116, 180, 39);
  font-weight: 520;
}

.card-body1{
  margin: 25px 0px;
  padding: 15px;
  padding:1px 0.5px;
}
.card-form{
  margin-top: 60px;
  background-color: rgb(246, 246, 246);
  display: flex;
  justify-content: center;
}
/* Breadcrumb*/
.breadcrumb{
  margin: -7px;
  background-color: rgb(255, 255, 255);
  font-weight: bold;
  font-size:40px ;
  line-height: 40px;
  color: #ffffff;
  text-shadow: .05em .05em 0 rgb(128, 128, 128);
  justify-content: center;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ".";
  color: #ffffff;
}
.breadCrumb1{
  margin-top: 60px;
  display: flex;
  align-items: center;
  font-size:50px ;
  justify-content: center;
  color:rgb(116, 180, 39);
}
/*Service*/
.Service-circle{
  display:flex;
  justify-content: center;
  margin-top: 8px;
  border: 5px solid rgba(154, 153, 153, 0.5);
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  max-width: 180px;
  height: 180px;
  padding: 5px;
  color: rgba(154, 153, 153);
}
.Service-circle:hover {
  border: 5px solid rgb(116, 180, 39);
  color: rgb(116, 180, 39);
  background-color: white;
  font-weight: bold;
}
.fa-briefcase{
  color: rgba(154, 153, 153);
  padding-top: 77px;
  align-items: center;
}
.fa-briefcase:hover{
  color: rgb(116, 180, 39);
}
/* Modal */
.modal{
  margin-top: 10%;
  height: 86%;
}
.modal-body{
  padding-left: 15px;
  width: 100%;
  overflow: none;
  font-size: 20px;
}
.my-modal .modal-content {
  background-color: rgb(252, 177, 78);
}
.my-modal1 .modal-content {
  background-color: rgb(129, 129, 129);
  color: #000000;
  height: 100%;
  width: 52%;
}
.modalContent{
  font-size:40px ;
  color:rgb(116, 180, 39);
}
.my-modals .modal-content{
  background-color: rgb(129, 129, 129);
}
.send-message{
background-color: rgb(116, 180, 39);
border-radius: 10px;
}
.send-message1{
  background-color: rgb(43, 43, 43);
  border-radius: 10px;
  margin-right: 1px;
}
/*Table*/
.card-body{
  table-layout: auto;
  margin: 25px 20px;
  border-bottom: 1px ridge;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  font-weight: bold;
}
.cardHeader{
  background-color: rgb(209, 233, 245);
  margin-right: 1px;
}
.send-message2{
  background-color: rgb(209, 233, 245);
  margin-right: 1px;
  border-radius: 10px;
}
dd{
  margin-top: "8px";
  padding:10px ;
  border-spacing: 10px;
  display: flex;
  justify-content: space-between;
}
.tableCell{
  font-size: 20px;
}
.tableCell:hover{
  color: rgb(116, 180, 39);
  font-size: 20px;
}
.tableCell1{
  color:rgb(116, 180, 39);
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Footer*/
.site-footer {
  padding: 10px 0px;
  background-color:rgb(42, 60, 81);
  color: #ffffff;
  font-family: Garamond , sans-serif;
  font-size: 21px;
}
.site-footer a{
  padding: 8px 0px;
  background-color:rgb(42, 60, 81);
  color: #ffffff;
  font-family: Garamond , sans-serif;
  font-size: 19px;
}
.footer-content{
  display: flex;
  justify-content: space-between;
  align-content:flex-end;
}
.btn-social-icon{
  margin: 0px 5px;
  padding:-5px;
  width:30px;
  height:30px;
}
/*Support Hover*/
.likes__wrapper{
  display:flex;
  align-content:center;
  justify-content:space-between;
}
.likes__relavance{
  position:relative;
}
.likes__list{
  margin-left: 30px;
  position:absolute;
  box-sizing:border-box;
  max-height:200px;
  width: 250px;
  font-size: 20px;
  font-weight: 700;
  background:rgb(42, 60, 81);
}
.unlist-modal{
  list-style-type: none;
  align-items: center;
  margin: 0px 10px;
  padding:10px;
}
/* Slide CSS*/
.slider{
  width: 100%;
  height: 82vh;
  position: relative;
  overflow: hidden;
}
.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: translateX(-100%);
  transition: all 0.5s ease;
}
@media screen and (min-width:600px){
.slide img {
  width: 100%;
  height: 100%;
}
}
.content {
  position: absolute;
  top: 0rem;
  left: 0rem;
  font-size: 55px;
  font-weight: bold;
  opacity: 0;
  width: 50%;
  height: 100%;
  padding: 3rem 3rem;
  background: rgba(172, 247, 97, 0.5);
  animation: slide-up 0.5s ease 0.5s;
  animation-fill-mode: forwards;
}
@keyframes slide-up{
0%{
  visibility: visible;
  top: 17rem;
}
}
@media screen and (max-width: 400px){
.content {
  width: 100%;
}
}
.content > * {
  color:rgb(27, 27, 27);
  margin-bottom: 1rem;
}
.current{
  opacity: 1;
  transform: translateX(0);
}
.current .content{
  opacity:1 ;
}
.btn-primary{
  margin: 1px 10px;
  border-radius: 10px;
  background-color: rgb(116, 180, 39);
  border: rgb(116, 180, 39);
  padding: 1px;
  font-weight: bold;
  font-size: 26px;
  color: #ffffff;
}
.btn-primary1{
  margin: 1px 10px;
  padding: 1px;
  font-weight: bold;
  font-size: 26px;
  color: rgb(97, 102, 93);
}








